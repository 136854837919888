/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~bootstrap/dist/css/bootstrap-grid.min.css";
@import "~fullcalendar/dist/fullcalendar.min.css";

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button {
    outline: none !important;
}

/* ESTILOS GLOBALES */
.custom-mat-tab-group .mat-tab-label {
  min-width: 65px !important;
  padding: 5px !important;
  font-size: x-small !important;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
  border: 2px solid #428bca !important;
  box-shadow: 2px 2px 7px 0 #1e1e1e !important;
  border-radius: 0 !important;
}
.tcla-dialog-container .mat-dialog-container {
  padding: 0 !important;
  border: 2px solid #428bca !important;
  border-radius: 4px;
  box-shadow: 0 0 0 0 black;
}
.tcla2-dialog-container .mat-dialog-container {
  padding: 0 !important;
  border: 2px solid #428bca;
  border-radius: 4px;
  box-shadow: 0 0 0 0 black;
}

.btn-primary-crm {
  background-color: #428bca;
  color: white;
  border: 1px solid #0074ce;
}
.btn-primary-crm:hover {
  filter: brightness(90%);
  color: white;
}

.my-tooltip {
  white-space: pre-line !important;
}
.btn-close-dialog {
  border-radius: 5px;
  transition: background-color 0.08s ease-in-out;
}
.btn-close-dialog:hover {
  background-color: brown;
}

.contenedor-formulario {
  padding: 20px;
  margin: 15px;
  border-radius: 0;
}
.contenedor-formulario-movil {
  padding: 20px;
  margin: 0;
  background-color: whitesmoke;
}

.spinner circle {
  stroke: #428bca!important;
}

.spinner-container {
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
}

.guardarDatos {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(100, 100, 100, 0.2);
}

.cabecera-modal {
  padding: 5px;
  text-align: center;
  background-color: #428bca;
  color: white;
  user-select: none;
  position: sticky;
  width: 100%;
  z-index: 1;
  box-shadow: 1px 1px 3px 0 gray;
  letter-spacing: 1px;
}

.icon-modal {
  position: absolute;
  top: 5px;
  left: 10px;
}

.cerrar-modal {
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 10px;
}
.cerrar-modal:hover {
  background-color: rgb(240, 12, 12);
}

.content-modal {
  display: block;
  height: calc(100% - 35px);
  overflow-y: auto;
}

.class-char {
  padding: 5px!important;
  font-size: xx-large!important;
  text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000!important;
}

.m-0-auto {
  margin: 0 auto!important;
}

.bg-mat {
  background-color: #f5f5f5!important;
}
